
import React, { useEffect } from 'react';
import latestBook from "../../images/wavecover3D.png";
import bg from "../../images/svgwaves.png";
import AOS from 'aos';
import 'aos/dist/aos.css';

const BookLandingPage = () => {

  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <div className="lg:py-16 py-1 bg-cover bg-center" style={{ backgroundImage:  `url(${bg})` }}>
      <div className="container  m-auto lg:px-6">
        <div className="lg:flex justify-between items-center">
          {/* Image div */}
          <div 
           data-aos="fade-down-left"
          //  data-aos-anchor="#example-anchor"
          //  data-aos-offset="5000"
           data-aos-duration="1000"
           className="lg:w-5/12 lg:order-2">
            <img 
            
              src={latestBook}
              alt="latest book by Misky - waves"
              className="w-auto h-[400px] lg:h-[500px] rounded"
              style={{ transform: 'scale(1) perspective(1040px) rotateY(-11deg) rotateX(2deg) rotate(2deg)' }}
            />
          </div>
          {/* Text div */}
          <div className="lg:w-6/12 lg:order-1 lg:p-0 p-3">
            <h1 className="text-4xl font-bold leading-normal mb-5 capitalize text-black">
              Let the rhythm of "WAVES" carry you away
            </h1>
            <p className="text-xl leading-[36px] text-gray-800">
              Dive into My latest masterpiece, a poetry collection that explores the depths of emotions and the currents of life.
            </p>
            <div className="flex mt-6 flex-col lg:flex-row gap-4 justify-center lg:justify-start">
              <a href='https://nuriakenya.com/' target='blank' className="inline-flex bg-secondaryColor items-center text-white hover:text-black justify-center px-5 py-3 lg:px-10 text-base font-medium hover:bg-accentColor">
                GET A COPY
              </a>
              <a href='#mybooks' className="inline-flex items-center justify-center px-5 lg:px-10 py-3 text-base border border-secondaryColor hover:bg-accentColor">
                SEE MY WORKS
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BookLandingPage;
