import React, { useEffect } from 'react';
import AOS from "aos";
import "aos/dist/aos.css";
import ilhan from "../../images/ilhan.png";
import booksigning from "../../images/booksigning.png";
import djibouti from "../../images/djibouti.png";
import djibouti2 from "../../images/Waves/new3.png";
import sompresident from "../../images/somaliapresident.png";
import Asomoah from "../../images/asomoah.png";
import mutua from "../../images/mutua.png";
import weta from "../../images/weta.png";
import presidentethiosom from "../../images/presidentethisom.png";

const MyEvents = () => {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <section id="proudmoments" className="p-4  lg:p-2 bg-gradint-to-b from-white to-red-50">
      <h2 className="lg:text-7xl text-5xl mt-10 px-5 lg:mb-10 text-secondaryColor font-playfair-display font-thin text-center">Proud Moments</h2>
      <div className="flex min-h-scn items-center justify-center py-10">
        <div className="grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-4">
         <EventCard
            image={djibouti2}
            title="Meeting The President of Djibouti - WAVES"
            description="Waves met the President's hand, a moment etched in memory."
            link="https://www.instagram.com/p/CXko2TBjAS0/"
          />
          <EventCard
            image={sompresident}
            title="Meeting The President of Somalia"
            description="A firm handshake, a weighty past. We discussed a future empowered by youth. 'RESET' wasn't just a book, it was a shared vision"
            link="https://www.instagram.com/p/ChkCvbFPg0I/?img_index=1"
          />
          <EventCard
            image={djibouti}
            title="Meeting The President of Djibouti - RESET"
            description="Amidst a firm handshake and profound history, we envisioned a future powered by youth. 'RESET' transcended its pages, emerging as our shared vision for change."
            link="https://www.instagram.com/p/CXko2TBjAS0/"
          />
        
          <EventCard
            image={ilhan}
            title="Meeting Ilhan"
            description="Meeting Ilhan Omar at the Somali awards sparked inspiration. Her support was truly validating. Being a nominee is an immense honor."
            link="https://www.instagram.com/p/Cp4wUZXj9IW/?img_index=5"
          />
          <EventCard
            image={booksigning}
            title="Book signing"
            description="The Jigjiga book signing was a resounding success! Engaging discussions about 'RESET' filled the air, a testament to the power of shared knowledge."
            link="https://www.instagram.com/p/CkBIbjnjh6r/?img_index=1"
          />
          <EventCard
            image={weta}
            title="Meeting Speaker Hon. Wetangula"
            description="Meeting the Speaker of Parliament, sharing 'RESET'. A moment brimming with transformative potential. Grateful for the opportunity."
            link="https://www.instagram.com/p/B4O4S5DjPH8/?img_index=1"
          />
          <EventCard
            image={mutua}
            title="Meeting CS Hon.Mutua"
            description="Encounter with the Foreign Affairs Cabinet Secretary, sharing 'RESET'. A moment filled with transformative promise. Grateful for the chance."
            link="https://www.instagram.com/p/CIsW2Cajfdo/"
          />
          {/* <EventCard
            image={presidentethiosom}
            title="Meeting The President of Somali Region of Ethiopia"
            description="Talked with President Mustafa of Somali Region, Ethiopia, and Prime Minister Somalia. Presented 'RESET'. Grateful for Somali fraternity's support."
            link="https://www.instagram.com/p/CkF23TyjSvP/"
          /> */}
          <EventCard
            image={Asomoah}
            title="Meeting Legend Asomoah Gyan"
            description="Asamoah Gyan, One of the best players our beautiful continent has ever produced received his RESET today. Wow. What a day it has been for me."
            link="https://www.instagram.com/p/Ca2e0o3jZck/?img_index=1"
          />
        </div>
      </div>
    </section>
  );
};

const EventCard = ({ image, title, description, link }) => {
  return (
    <div className="group relative cursor-pointer items-center justify-center overflow-hidden transition-shadow hover:shadow-xl hover:shadow-black/30" data-aos="zoom-in" data-aos-duration="1000">
      <div className="h-96 w-72">
        <img className="h-full rounded-xl w-full object-cover transition-transform duration-500 group-hover:rotate-3 group-hover:scale-125" src={image} alt="" />
      </div>
      <div className="absolute inset-0 bg-gradient-to-b from-transparent via-transparent to-black group-hover:from-black/70 group-hover:via-black/70 group-hover:to-black/70"></div>
      <div className="absolute inset-0 flex translate-y-[50%] flex-col items-center justify-center px-9 text-center transition-all duration-500 group-hover:translate-y-0">
        <h6 className=" text-3xl font-bold font-playfair-display text-white">{title}</h6>
        <p className="mb-3 text-lg italic text-white opacity-0 transition-opacity duration-300 group-hover:opacity-100">{description}</p>
        <button className="rounded-full bg-accentColor py-2 px-3.5 font-com text-sm capitalize text-black shadow shadow-black/60">
          <a href={link} target="_blank" rel="noopener noreferrer">Read More</a>
        </button>
      </div>
    </div>
  );
};

export default MyEvents;
