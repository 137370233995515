import React from 'react';

import heroimg from "../../images/Kilifi Pads drive and Girl child empowerment/IMG-4628-1.webp"

const ForeverHomeSection = () => {
  return (
    <section
    className="relative h-full   bg-cover bg-center bg-no-repeat"
    style={{ backgroundImage: `url(${heroimg})` }}
  >
    <div
      className="absolute inset-0 bg-white/75 sm:bg-transparent sm:from-white/95 sm:to-white/25 sm:bg-gradient-to-r "
    ></div>
  
    <div
      className="relative mx-auto max-w-screen-xl px-4 py-32 sm:px-6 lg:flex lg:h-screen lg:items-center lg:px-8"
    >
      <div className="max-w-xl text-center sm:text-left ">
        <h1 className="text-3xl font-extrabold sm:text-5xl">
           Misky Nur Foundation
  
          <strong className="block font-extrabold text-pink-500"> Forever Home. </strong>
        </h1>
  
        <p className="mt-4 max-w-lg sm:text-xl/relaxed">
           Together, we can
          Learn, Relearn and Reset
        </p>
  
        <div className="mt-8 flex flex-wrap gap-4 text-center">
          <a
            href="#about"
            className="block w-full rounded bg-secondaryColor px-10 py-3 text-lg font-medium text-white shadow hover:bg-blue-900 focus:outline-none focus:ring active:bg-gray-900 sm:w-auto"
          >
            About Us 
          </a>
  
          {/* <a
            href="#"
            className="block w-full rounded bg-white px-12 py-3 text-sm font-medium text-rose-600 shadow hover:text-rose-700 focus:outline-none focus:ring active:text-rose-500 sm:w-auto"
          >
            Learn More
          </a> */}
        </div>
      </div>
    </div>
  </section>
  );
};

export default ForeverHomeSection;
