
import veiled from "../../images/veiled5.jpeg"

import React from 'react';

const LandingPage = () => {
  return (
    <div className="flex flex-wrap md:items-center h-scren" 
    // style={{  backgroundImage: `url(${veiled})`, backgroundSize: 'cover', backgroundPosition: 'centr',  backgroundRepeat: 'no-repeat' }}
    >

      <div className=" w-full order-2 md:order-1 md:w-1/2 h-sceen">
        <div className="lg:mx-28 md:mx-  mx-2">
          <h1 className="md:text-[62px] text-[40px] mt-6 font-normal  hidden italic text-amber-600 mb-1 bg-rd-700 md:mt-2">The Veiled Podcast</h1>
           
          <h2 className="itali mt-6 font-playfair-display md:mt-0 font-sans md:text-4xl text-2xl px-4 font-bold tracking-wide text-gray-900 ">
            spirited conversations  that captivate <span className="inline-bloc font-playfair-display text-amber-600">  both the eyes and ears.   </span>
            {/* <br className=" md:block" /> */}
             {/* that captivate  {' '} */}
            
           
          
          </h2>


          {/* Description */}
          <div className=" mb-4 px-4 bg-ornge-800 p- leading-8 w-full sm: md:w-3/3 mt-4 text-black text-xl">
            An empowering space where Muslimah share triumphs, tackle girl troubles, 
            and explore the intersection of faith and contemporary life. Join us for 
            candid conversations that <span className='text-amber-600'> unveil </span>the strength, resilience, and diverse voices of Muslim women. 
          </div>
           {/* links */}
          <div className="flex px-4 md:justify-start mb-8 md:mb-0 items-center">
            <a
              href="#episodes"
              className="inline-flex items-center justify-center h-12 px-6 mr-6 font-medium tracking-wide text-black transition duration-200 rounded shadow-md bg-amber-600 hover:bg-amber-800 focus:shadow-outline focus:outline-none"
            >
              Latest Episodes
            </a>
            <a
              href="#about"
              aria-label=""
              className="inline-flex items-center font-semibold text-gray-900 transition-colors duration-200 hover:text-amber-700"
            >
              About us
            </a>
          </div>
        </div>
      </div>

      

      <div className=" order-1 md:order-2 w-full md:w-1/2 h-scren">
        <img
          src={veiled}
          className="lg:h-[600px] sm:h-[480px] h-auto w-full"
          alt=""
        />
      </div>
      
    </div>
  );
}

export default LandingPage;
