import React from 'react';

const Episodes = () => {
    return (
        <section id='episodes' className=" text-gray-900">
              <h2 className="mt-5 text-4xl font-playfair-display md:text-5xl lg:text-6xl font-thin text-center lg:mt- mb-4 lg:mb-8">
                Our Latest Episodes
              </h2>
            <div className="container max-w-6xl p-6 mx-auto space-y-6 sm:space-y-8">
                {/* episode 3 */}
                {/* <div className="block max-w-sm gap-3 mx-auto sm:max-w-full group hover:no-underline rounded-xl  focus:no-underline lg:grid lg:grid-cols-12 shadow-lg bg-gray-00"
                  style={{background : "#452312"}}>
                    <iframe
                        style={{ borderRadius: '12px' }}
                        src="https://open.spotify.com/embed/show/55WbhS13T7xEN40pqaCwRd?utm_source=generator"
                        width="100%"
                        height="352"
                        frameBorder="0"
                        allowFullScreen=""
                        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
                        loading="lazy"
                        className="object-cover w-full h-64 rounded sm:h-96 lg:col-span-6 dark:bg-gr-500"
                    ></iframe>
                    <div className="p-6 space-y-2 lg:col-span-6">
                        <h3 className="text-2xl font-semibold sm:text-4xl group-hover:underline group-focus:underline">Wanting a financially stable person does not make you a gold digger</h3>
                        <span className="text-md text-gray-400">Mar 6, 2024</span>
                        <p className='text-lg'>In today's episode, we delve into the profound beauty of childbirth through the eyes of a midwifery student. We challenge societal stereotypes and misconceptions surrounding women and money. We emphasize that a woman's desire for financial independence and success does not make her a gold digger.</p>
                    </div>
                </div> */}


                <div className="grid justify-center grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">

                 <div className="max-w-sm rounded-xl mx-auto group hover:no-underline focus:no-underline shadow-lg"
                //  style={{background : "#fbe8eb"}}
                 >
                        <iframe
                            style={{ borderRadius: '12px' }}
                            
                            src="https://open.spotify.com/embed/show/55WbhS13T7xEN40pqaCwRd?utm_source=generator"
                            width="100%"
                            height="352"
                            frameBorder="0"
                            allowFullScreen=""
                            allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
                            loading="lazy"
                            className="object-cover w-full rounded h-64 dark:bg-gay-500"
                            role="presentation"
                        ></iframe>
                        <div className="p-6 space-y-2">
                            <h3 className="text-2xl font-semibold group-hover:underline group-focus:underline">Wanting a financially stable person does not make you a gold digger</h3>
                            <span className="text-md text-gray-400">Mar 6, 2024</span>
                            <p className='text-lg'>In today's episode, we delve into the profound beauty of childbirth through the eyes of a midwifery student. We challenge societal stereotypes and misconceptions surrounding women and money. We emphasize that a woman's desire for financial independence and success does not make her a gold digger.</p>
                        </div>
                    </div>
                    {/* episode 2 */}
                    <div className="max-w-sm mx-auto group hover:no-underline rounded-xl shadow-lg focus:no-underline bg-gray-90"
                    //  style={{background : "#fbe8eb"}}
                     >
                        <iframe
                            style={{ borderRadius: '12px' }}
                            // src="https://open.spotify.com/embed/episode/3fwlMM38FIYWV2ooGzyL3T?utm_source=generator&theme=0"
                            src="https://open.spotify.com/embed/episode/3fwlMM38FIYWV2ooGzyL3T?utm_source=generator"
                            width="100%"
                            height="500"
                            frameBorder="0"
                            allowFullScreen=""
                            allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
                            loading="lazy"
                            className="object-cover w-full rounded h-64 dark:bg-gry-500"
                            role="presentation"
                        ></iframe>
                        <div className="p-6 space-y-2">
                            <h3 className="text-2xl font-semibold group-hover:underline group-focus:underline">This is a battle between African and Australian women!!!</h3>
                            <span className="text-xs text-gray-400">Feb 21, 2024</span>
                            <p className='text-lg'>In this week's podcast episode, we delve into the world of university life, exploring the experiences of both international and local students, as well as navigating conversations with parents about future plans.</p>
                        </div>
                    </div>

                    <div className="max-w-sm rounded-xl mx-auto group hover:no-underline focus:no-underline shadow-lg" 
                    // style={{background : "#fbe8eb"}}
                    >
                        <iframe
                            style={{ borderRadius: '12px' }}
                            src="https://open.spotify.com/embed/episode/2vBRtSLLCIb2L0oH3zLoWo?utm_source=generator"
                            width="100%"
                            height="352"
                            frameBorder="0"
                            allowFullScreen=""
                            allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
                            loading="lazy"
                            className="object-cover w-full rounded h-64 dark:bg-gay-500"
                            role="presentation"
                        ></iframe>
                        <div className="p-6 space-y-2">
                            <h3 className="text-2xl font-semibold group-hover:underline group-focus:underline">Getting comfortable with the uncomfortable</h3>
                            <span className="text-xs text-gray-400">January 31, 2024</span>
                            <p className='text-lg'>Welcome to the first episode of The Veiled Podcast! Join us as we dive into the origins of our podcast, discussing why we embarked on this exciting journey. We explore the contrasts between life in the West and Africa, shedding light on the differences.</p>
                        </div>
                    </div>

                  

                  
                </div>
            </div>
        </section>
    );
}

export default Episodes;
