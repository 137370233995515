import React from 'react';

import { jobdata } from '../../data'; 

const WhatWeDo = () => {
  return (
    <section id='whatwedo' className='lg:px-32 pt-6 lg:pt-1   px-2'>
        <h2 className="mt-2  font-playfair-display lg:p-8 p-2 text-4xl md:text-5xl lg:text-6xl font-thin text-center lg:mt-0 mb-12 lg:mb-6">
            <span className="relative">
              What  We Do
            {/* <span className="border-b-4 border-black absolute bottom-0 left-0 right-0"></span> */}
            </span>
         </h2>
      <div  className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 md:gap-6 lg:gap-0 lg:grid-cols-3 ">
        {jobdata.map((item, index) => (
          <div data-aos="zoom-in-up" data-aos-duration="1000"
            key={index}
            className="group rounded-lg relative cursor-pointer overflow-hidden bg-white px-6 pt-10 pb-8 shadow-xl ring-1 ring-gray-900/5 transition-all duration-300 hover:-translate-y-1 hover:shadow-2xl mx-4 my-4 sm:mx-auto sm:max-w-sm sm:rounded-lg sm:px-10"
          >
            <span className="absolute top-10 z-0 h-20 w-20 rounded-full bg-purple-400 transition-all duration-300 group-hover:scale-[10]"></span>
            <div className="relative z-10 mx-auto max-w-md">
              <span className="grid h-20 w-20 text-4xl place-items-center rounded-full bg-purple-400 transition-all duration-300 group-hover:bg-purple-400">
                {item.icon}
              </span>
              <h3 className='text-2xl font-playfair-display mt-2 group-hover:text-white/90 '>{item.title}</h3>
              <div className="space-y-6 pt-2 tex-base text-lg leading-7 text-gray-900 transition-all duration-300 group-hover:text-white/90">
                <p>{item.description}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default WhatWeDo;
