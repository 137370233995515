import React from 'react';
import veiled from "../../images/veiled8.jpg"
import { FaYoutube, FaSpotify } from 'react-icons/fa';
import backgroundImage from "../../images/polygon.svg"
const About = () => {
  return (
    <div id='about' className="py-6 " 
    // style={{ backgroundImage: `url(${backgroundImage })`, backgroundSize: 'cover', backgroundPosition: 'center' }}
    >
      <div className="container m-auto px-6">
         
           <h2 className="mt-5 p-2 text-4xl font-playfair-display md:text-5xl lg:text-6xl font-thin text-center lg:mt-2 mb-4 lg:mb-4">
            More About Us
           </h2>
        <div className="lg:flex  justify-between items-center">
          <div className="lg:w-5/12 ">
            <img src={veiled}
            // src="https://images.unsplash.com/photo-1542435503-956c469947f6?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=967&q=80"
            //   style={{ transform: 'scale(1) perspective(1040px) rotateX(-11deg) rotateY(2deg) rotate(2deg)' }}
              alt="the vailed podcast microphone"
              className="rounded"
            />
          </div>
          <div className="lg:w-5/12 pt-8 md:pt-0">
            <h1 className="text-4xl font-bold md:mt-5 text-amber-600 leading-tight mb-5 font-playfair-display capitalize">Veiled Voices</h1>
            <p className="text-xl px-2"> From personal victories to societal observations, each episode celebrates the
                                    unique experiences of <span className='text-amber-600'>veiled</span>  women 
                                    navigating the world. Tune in for quick-witted banter, insightful discussions, 
                                    and empowering narratives. <span className='text-amber-600'>veiled</span>    Podcast is your go-to destination for short, 
                                    sweet, and spirited conversations that captivate both the eyes and ears. 
            </p>

            <div className="py-5 flex flex-col md:flex-row gap-3">
                <a href="https://open.spotify.com/show/55WbhS13T7xEN40pqaCwRd" target='blank' className="text-black text-center w-full md:w-1/2  rounded-md py-2 px-2 text-lg font-medium bg-orange-200 inline-block border">
                        <FaSpotify className="inline-block text-3xl text-green-600 mr-1" /> Listen on Spotify
                </a>
                <a href="www.youtube.com/@The.Veiled" target='blank' className="text-black text-center rounded-md w-full md:w-1/2 py-2 px-2 text-lg font-medium bg-orange-200 inline-block border">
                    <FaYoutube className="inline-block text-3xl text-red-600 mr-1" /> Listen on YouTube
                </a>
               
            </div>

          </div>
        </div>

      </div>
    </div>
  );
};

export default About;
