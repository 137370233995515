import React, { useEffect } from 'react';
import { herodata } from '../../data'; 
import Aboutme from './Aboutme';
import SocialLinks from './socialLinks';
import Awards from './Awards.js';
import MyEvents from './MyEvents.js';
import { Link } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Helmet } from 'react-helmet-async';
import Alert from '../Alert.js';

function LandingPage() {
  const { landingPage } = herodata;

  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <section id="home">
      <Helmet>
        <title>misky nur Abdullahi - Author, Poet and Youth Advocate </title>
        <meta name="description" content="Author, poet, advocate, and future lawyer passionate about using the power of words to inspire and empower young people. Join me on a journey of positive change and exploration." />
        <link rel="canonical" href="https://miskynur.me/" />
      </Helmet>
      {/* <Alert /> */}
      <section className="flex flex-col md:flex-row justify-center items-center gap-4 lg:py-1 px-4 lg:px-20" >
        {/* Textual Content */}
        <div className="bg-rd-900 flex-1 mt-10 sm:mt-0">
          <h1  className="text-6xl leading-[60px] lg:text-8xl lg:text-center font-quicksand font-quicksand-normal font-playfair-dislay font-playfair-weiht">
            <span>Hey</span> <br />{landingPage.name}
          </h1>
          <p data-aos="fade-right" data-aos-duration="2000" 
            className=" font-quicksand font-quicksand-normal py-4 text-left lg:px-10 md:text-center leading-8 text-xl">
            {landingPage.description}
          </p>
          <div data-aos="fade-up" data-aos-duration="2000"  className="flex flex-col font-quicksand font-quicksand-semibold  md:flex-row gap-3 lg:gap-6 justify-center py-2 lg:p-2 px-">
            <Link to="/books" className="inline-flex border-2 bg-accentColor items-center justify-center px-5 py-3 lg:px-10 text-base font-medium hover:bg-purple-300">
              SEE MY BOOKS
            </Link>
            <Link to="/foundation" className="inline-flex items-center justify-center px-5 lg:px-10 py-3 text-base border-2 border-accentColor hover:bg-accentColor">
              VISIT MY FOUNDATION
            </Link>
          </div>
        </div>

        {/* Image */}
        <div >
          <div className="flex w-full">
            <img src={landingPage.image} alt="miskynur" className="w-[25rem] object-cover h-[20rem] lg:h-[35rem] object-cover" />
          </div>
        </div>
      </section>

      <SocialLinks />
      <Aboutme />
      <Awards />
      <MyEvents />
    </section>
  );
}

export default LandingPage;
