// data.js
import hero from "./images/misky18.png"
import hero2 from "./images/misky19.webp"
import award from "./images/award4.jpeg"
import award2 from "./images/award1.jpeg"
import award3 from "./images/award2.jpeg"
import aboutimg from "./images/misky2.webp"

// foundation image
import foundationAboutImage from "./images/Kilifi Pads drive and Girl child empowerment/IMG-4634-1.jpg"
import missionImage from "./images/Marry happy school mentorship/IMG_4576.jpg"

// what we care img
import educationimg from "./images/education.jpg"
import Climateimg from "./images/climate.jpg"
import dreamimg from "./images/dream.jpg"


// import icons
import {
    IoLogoYoutube,
    IoLogoFacebook,
    IoLogoInstagram,
    IoMdAddCircle,
    IoIosCheckmarkCircle,
    IoIosArrowRoundForward,
    IoLogoWhatsapp,
    IoLogoTwitter,
  } from 'react-icons/io';

import { FaTiktok } from "react-icons/fa6";

import { FaUserFriends, FaFemale, FaGraduationCap, FaRegLightbulb, FaHandshake, FaHeartbeat } from 'react-icons/fa';


// book images data

import event_1 from './images/reset _in_wajir/IMG-2646.jpg';
   import event_1_1 from './images/reset _in_wajir/IMG-4597.jpg';
   import event_1_2 from './images/reset _in_wajir/IMG-4598-1.jpg';
   import event_1_3 from './images/reset _in_wajir/IMG-4599.jpg';
   import event_1_4 from './images/reset _in_wajir/IMG-4600.jpg';
   import event_1_5 from './images/reset _in_wajir/IMG-4601-1.jpg';
   import event_1_6 from './images/reset _in_wajir/IMG-4602.jpg';
   import event_1_7 from './images/reset _in_wajir/IMG-4604.jpg';
   import event_1_8 from './images/reset _in_wajir/IMG-2646.jpg';

import event_2 from './images/Reset charter in Mandera/IMG-0001.jpg';
  import event_2_1 from './images/Reset charter in Mandera/IMG-0001.jpg';
  import event_2_2 from './images/Reset charter in Mandera/IMG-0003.jpg';
  import event_2_3 from './images/Reset charter in Mandera/IMG-0005.jpg';
  import event_2_4 from './images/Reset charter in Mandera/IMG-0007.jpg';
  import event_2_5 from './images/Reset charter in Mandera/IMG-0008-1.jpg';
  import event_2_6 from './images/Reset charter in Mandera/IMG-0010.jpg';
  import event_2_7 from './images/Reset charter in Mandera/IMG-0013.jpg';
  
import event_3 from './images/reset in mombasa/msa1.jpg';
  import  event_3_1 from './images/reset in mombasa/1a46952b-267e-4662-9ee1-1ac1805099a2.jpg';
  import  event_3_2 from './images/reset in mombasa/2fb970a4-0dac-449a-a5af-1e3be39d7cb9-1.jpg';
  import  event_3_3 from './images/reset in mombasa/3b9e65a9-8a80-4202-80a8-7d7448d69731.jpg';
  import  event_3_4 from './images/reset in mombasa/3b9e65a9-8a80-4202-80a8-7d7448d69731.jpg';
  import  event_3_5 from './images/reset in mombasa/6E3B683B-4B39-4EDC-8001-8D99BF15C6DC.jpg';
  import  event_3_6 from './images/reset in mombasa/19d42714-743a-4614-8a17-5e31d59d8d5f-1.jpg';
  import  event_3_7 from './images/reset in mombasa/041a97b4-a251-473d-9e4d-c28f1f4cb8d8.jpg';
  import  event_3_8 from './images/reset in mombasa/msa1.jpg'

import event_4 from './images/Marry happy school mentorship/IMG_4575-1.jpg';
  import  event_4_1 from './images/Marry happy school mentorship/IMG_4575-1.jpg';
  import  event_4_2 from './images/Marry happy school mentorship/IMG_4576.jpg';
  import  event_4_3 from './images/Marry happy school mentorship/IMG_4577.jpg';
  // import  event_4_4 from '../images/Marry happy school mentorship/3b9e65a9-8a80-4202-80a8-7d7448d69731.jpg';
  // import  event_4_5 from '../images/Marry happy school mentorship/6E3B683B-4B39-4EDC-8001-8D99BF15C6DC.jpg';
  // import  event_4_6 from '../images/Marry happy school mentorship/19d42714-743a-4614-8a17-5e31d59d8d5f-1.jpg';
   
import event_5 from './images/Deception by Amal Launch/IMG-3760.jpg';
  import  event_5_1 from './images/Deception by Amal Launch/28f83df0-b9f0-4ded-8573-f282cf0a28e9.jpg';
  import  event_5_2 from './images/Deception by Amal Launch/55b903b6-654c-469f-8b7b-1b9664e1ea90.jpg';
  import  event_5_3 from './images/Deception by Amal Launch/86556478-f775-4dd6-bf86-380c9e8c7d9d.jpg';
  import  event_5_4 from './images/Deception by Amal Launch/IMG-3791-2048x1536.jpg';
  import  event_5_5 from './images/Deception by Amal Launch/IMG-3760.jpg';
  import  event_5_6 from './images/Deception by Amal Launch/IMG-3788.jpg';

import event_6 from './images/Kilifi Pads drive and Girl child empowerment/IMG-4624.jpg';
  import  event_6_1 from './images/Kilifi Pads drive and Girl child empowerment/IMG-4626.jpg';
  import  event_6_2 from './images/Kilifi Pads drive and Girl child empowerment/IMG-4628-1.jpg';
  import  event_6_3 from './images/Kilifi Pads drive and Girl child empowerment/IMG-4629.jpg';
  import  event_6_4 from './images/Kilifi Pads drive and Girl child empowerment/IMG-4630.jpg';
  import  event_6_5 from './images/Kilifi Pads drive and Girl child empowerment/IMG-4632-1.jpg';
  import  event_6_6 from './images/Kilifi Pads drive and Girl child empowerment/IMG-4633.jpg';
  import  event_6_7 from './images/Kilifi Pads drive and Girl child empowerment/IMG-4634-1.jpg';


// WAVES PHOTOS DJIBOUTI
 import pic_1 from "./images/Waves/new3.png"
 import pic_2 from "./images/Waves/New4.JPG"
 import pic_3 from "./images/Waves/new6.JPG"
 import pic_4 from "./images/Waves/new12.jpeg"
 import pic_5 from "./images/Waves/new10.JPG"
 import pic_6 from "./images/Waves/new2.png"
 import pic_7 from "./images/Waves/new14.jpeg"
 import pic_8 from "./images/Waves/new16.jpeg"

// WAVES LAUNCH
 import waves_img_1 from "./images/waveslaunch/L1.jpeg"
 import waves_img_2 from "./images/waveslaunch/L2.jpeg"
 import waves_img_3 from "./images/waveslaunch/L4-MP.jpeg"
 import waves_img_4 from "./images/waveslaunch/L5-MP.jpeg"
 import waves_img_5 from "./images/waveslaunch/L7.jpeg"
 import waves_img_6 from "./images/waveslaunch/L8.jpeg"
 import waves_img_7 from "./images/waveslaunch/L9.jpeg"
 import waves_img_8 from "./images/waveslaunch/L10.jpeg"


// book images
import waves from  "./images/WAVECover.jpg"
import reset from "./images/reset.jpeg"

import img1 from "./images/reset _in_wajir/IMG-2646.jpg";
import img2 from "./images/reset _in_wajir/IMG-4597.jpg";
import img3 from "./images/reset _in_wajir/IMG-4598-1.jpg";
import img4 from "./images/reset _in_wajir/IMG-4599.jpg";
import img5 from "./images/reset _in_wajir/IMG-4602.jpg";
import img6 from "./images/reset _in_wajir/IMG-2646.jpg";
import img7 from "./images/reset _in_wajir/IMG-2646.jpg";
import img8 from "./images/reset _in_wajir/IMG-2646.jpg";
import img9 from "./images/reset _in_wajir/IMG-2646.jpg";
import img10 from "./images/reset _in_wajir/IMG-2646.jpg";

const imageData = [img1, img2, img3, img4, img5];

export default imageData;


export const herodata = {
    landingPage: {
      name: "I'M Misky ",
      titles: "Poet, Author, Youth Advocate",
      description: "A young author, poet, advocate, and aspiring lawyer, With a passion for empowering youth. I strive to make a difference in my community and beyond. Join me on this journey as we explore the power of words and advocate for positive change.",
      image: hero2, 
      banners: [
        { title: "Youth Advocate", color: "green" },
        { title: "Author", color: "blue" },
        { title: "Poet", color: "purple" },
       
      ]
    },
    aboutMe: {
      image: aboutimg, 
      description: `I am Misky Nur Abdullahi, an author, poet, and advocate dedicated to empowering youth worldwide. In 2019, my debut poetry book, "RESET," captured global attention, drawing admiration from leaders like Presidents of Djibouti and Somalia. Through the Misky Foundation, I champion education for young girls and boys globally. Speaking at international forums, including the UN Youth Conference, I've been recognized among Kenya's "100 Most Influential Youth." Nominated at the Somali International Awards and honored for community contributions in Australia, my journey continues with the upcoming launch of "WAVES," my second poetry book, and "The Veiled Podcast," amplifying voices of empowerment.`,
      buttons: [
        { text: "Books", link: "/books" },
        { text: "Foundation", link: "/foundation" },
        { text: "Podcast", link: "/podcast" },
        { text: "Blogs", link: "/blogs" },
        { text: "Events", link: "/events" }
      ]
    }
  };
  
  export const socials = {
    social: [
      {
        socialmediaicon: <IoLogoTwitter />,
        numberOfFollowers: "30k+",
        href: 'https://twitter.com/MiskyHaji',
        socialMediaName: "X formally Twitter"
      },
      {
       socialmediaicon: <IoLogoInstagram />,
        numberOfFollowers: "80k+",
        href: 'https://www.instagram.com/misky_hajji/',
        socialMediaName: "Instagram"
      },
      {
       socialmediaicon: <IoLogoFacebook />,
        numberOfFollowers: "30k+",
        href: 'https://www.facebook.com/misky.nur.5',
        socialMediaName: "Facebook"
      },
      {
       socialmediaicon: <FaTiktok />,
        numberOfFollowers: "620k+",
        href: 'https://www.tiktok.com/@miskyhajji',
        socialMediaName: "Tiktok"
      },
    ]
    }


  export const awardsData = {
    awards: [
      {
        title: "Recognition Award",
        description: "Recognition Award in the somali community in Melbourne Australia ",
        imageUrl: award,
      },
      {
        title: "Exceptional individual",
        description: "exceptional individual that made significant contributions in the community - Melbourne Australia",
        imageUrl: award2,
      },
      {
        title: "Guest",
        description: "invited guest at muslim awards Australia",
        imageUrl: award3,
      }
    ]
  };
  
  // foundation data

 export const aboutData = {
    whoWeAre: {
      title: "Who we are",
      description: "Misky Nur Foundation is a Kenyan based nonprofit organization founded by Misky Hajji to support mainly Education and empower women and youth in the society. The aim is to make sure everyone gets quality education and good guidance to achieve their dreams without discrimination. Our team has expanded and we have spread out in different regions to make sure we cover all possible areas and help as many people as possible",
      image: foundationAboutImage,
    },
    visionAndMission: {
      title: "Our Vision and Mission",
      description: "To offer the highest quality of education, Mentorship, empowerment delivered with empathy, thoughtfulness, inclusiveness and care",
      image: missionImage
    },
  };
  
  
  // data for what we do component
  export const jobdata = [
    {
      icon: <FaUserFriends />,
      title: "Mentorship",
      description: "Mentorship is very vital since it helps in the improvement of personal prospects. We make sure we mentor every young person who comes our way and we are looking for more ways to mentor everyone in the society."
    },
    {
      icon: <FaFemale />,
      title: "Empower Women",
      description: "Women are the strongest pillar in our society to date and no woman should be discriminated or denied their rights. We support every woman and empower them to be their better selves and discourage any type of discrimination against women."
    },
    {
      icon: <FaGraduationCap />,
      title: "We Educate",
      description: "Today’s youth need a helpful hand and right guidance at every stage. Here’s where we take care of them like our own. Education is part of it. Every child or youth has a dream and education plays a huge role in nurturing that dream."
    },
    {
      icon: <FaRegLightbulb />,
      title: "Empowering the youth",
      description: "Youth are the backbone of the society. They are the next generation leaders. With brilliant youth, our next generation will be safe."
    },
    {
      icon: <FaHandshake />,
      title: "Fight for end of FGM",
      description: "At Misky Nur Foundation one of our major initiative is to help end FGM which has negative impact to the society. We will continue to advocate and enlighten the community on the importance to end FGM."
    },
    {
      icon: <FaHeartbeat />,
      title: "We Strengthen",
      description: "We believe that education, and employment gives people an inner strength and unity to lead a better life."
    }
  ];
  

  // data for what we care component
 export  const WhatWeCaredata = [
    {
      title: "Education",
      description: "Everyone deserves quality education and better guidance. At Misky Foundation, education is part of our core values and together with our partners we will continue to make an impact.",
      image: educationimg,
      num: 1
    },
    {
      title: "Climate Change",
      description: "Climate change affects us a lot and a step to help in it is by planting trees, disposing plastic waste at designated places and educating the public on the dangers of neglecting the environment.",
      image: Climateimg,
      num: 2
    },
    {
      title: "Empowering Dreams",
      description: "The underprivileged children do not have access to required financial help and resources needed to live the life of their dreams. Our goal is to help them with everything they’ll need to live their dreams.",
      image: dreamimg,
      num: 3
    }
  ];
  

// books content
export const booksData = [
  {
      image: waves,
      description: `"Waves" is a captivating collection of poetry that takes you on a thrilling journey to learn
      and appreciate human experiences, with the social topics expressed in fascinating and
      emotional depth. Through the lens of poetic expression, the author Steers through the
      challenges of today's existence shedding light on themes that universally connect.
      The poems within this collection are a reflection of the author's keen observation and
      deep empathy for the human experience. Themes of social life, equality, love, pain,
      identity, and resilience hence creating an environment that reflects the diverse nature of
      society.
      Each poem triggers a sparking conversation about the societal framework that impact
      our lives today. "WAVES" is a poetic influence that invites readers to witness the beauty
      and challenges of the`,
      link: "https://nuriakenya.com/"
  },
  {
      image: reset,
      description: `RESET is a collection of poems which is social,economic and political challenges in our society at large and of course some special poems for the pastoral community 
      Sensitive topics like;tribalism,nepotism,leadership applies to all!
      Our main people (youths)who need attention and hope as well!
      No matter how the water is bitter let’s try our best to finish it!
      Reset your mind !it starts with you! It’s never late!`,
      link: "https://nuriakenya.com/"
  }
];


export const testimonialsData = [
  {
    text: "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Voluptatibus quibusdam, eligendi exercitationem molestias possimus facere.",
    author: "Leroy Jenkins"
  },
  {
    text: "Accusantium illum cupiditate harum asperiores iusto quos quasi quis quae! Fugit doloribus, voluptatum quidem magnam velit excepturi nobis, reprehenderit ducimus incidunt quisquam quae veritatis, quos iure harum.",
    author: "Leroy Jenkins"
  },
  {
    text: "Accusantium illum cupiditate harum asperiores iusto quos quasi quis quae! Fugit doloribus, voluptatum quidem magnam velit excepturi nobis, reprehenderit ducimus incidunt quisquam quae veritatis, quos iure harum.",
    author: "Leroy Jenkins"
  },
  {
    text: "Accusantium illum cupiditate harum asperiores iusto quos quasi quis quae! Fugit doloribus, voluptatum quidem magnam velit excepturi nobis, reprehenderit ducimus incidunt quisquam quae veritatis, quos iure harum.",
    author: "Leroy Jenkins"
  },
  {
    text: "Accusantium illum cupiditate harum asperiores iusto quos quasi quis quae! Fugit doloribus, voluptatum quidem magnam velit excepturi nobis, reprehenderit ducimus incidunt quisquam quae veritatis, quos iure harum.",
    author: "Leroy Jenkins"
  },
  {
    text: "Accusantium illum cupiditate harum asperiores iusto quos quasi quis quae! Fugit doloribus, voluptatum quidem magnam velit excepturi nobis, reprehenderit ducimus incidunt quisquam quae veritatis, quos iure harum.",
    author: "Leroy Jenkins"
  }
];

  
export const testimonial = {
  title: 'What people are saying about us',
  image: hero,
  persons: [
    {
      avatar: award ,
      name: 'Josh Smith',
      occupation: 'Manager of The New York Times',
      message:
        '“They are have a perfect touch for make something so professional ,interest and useful for a lot of people .”',
    },
    {
      avatar: award2,
      name: 'Brandi Johns',
      occupation: 'Manager of The New York Times',
      message:
        '“They are have a perfect touch for make something so professional ,interest and useful for a lot of people .”',
    },
    {
      avatar: award2,
      name: 'Paula Pfeffer',
      occupation: 'Manager of The New York Times',
      message:
        '“They are have a perfect touch for make something so professional ,interest and useful for a lot of people .”',
    },
  ],
};


// book events data 

export const eventsData = [
  {
    id: 7,
    name: 'WAVES Launch',
    thumbnail: [event_2],
    description: `My heart soared as "Waves" officially arrived! The Melbourne launch was a whirlwind of laughter, thoughtful discussions, and a love of literature that warmed the entire room. It was a true celebration of the written word.`,
    images: [
      [waves_img_1],
      [waves_img_2],
      [waves_img_3],
      [waves_img_4],
      [waves_img_5],
      [waves_img_6],
      [waves_img_7],
      [waves_img_8],
   ],
  },
  {
    id: 8,
    name: 'WAVES in Djibouti',
    thumbnail: [event_2],
    description: `The Second Edition of the Djibouti Book Fair was an experience I'll never forget. To be invited by the President of Djibouti himself was an incredible honor. Sharing my work, the warmth of the community, and even having a one-on-one chat with the President – it was a dream come true.`,
    images: [
      [pic_1],
      [pic_2],
      [pic_3],
      [pic_4],
      [pic_5],
      [pic_6],
      [pic_7],
      [pic_8],
   ],
  },
  {
    id: 2,
    name: 'Reset in mandera',
    thumbnail: [event_2],
    description: `This event was held at the Granada Hotel in Mandera graced by the Deputy Governor of Mandera County H.E Mohamed Arai and other Key leaders of the great Mandera county.
                  The Mandera Reset Charter was in partnership with the royal embassy of Saudi Arabia. They were in support of every work I was doing and made everything possible. This was quite a homecoming and my hometown was amazing. I felt even more motivated.`,
    images: [
      [event_2_1],
      [event_2_2],
      [event_2_3],
      [event_2_4],
      [event_2_5],
      [event_2_6],
      [event_2],
      [event_2_7],
   ],
  },
  {
    id: 1,
    name: 'Reset in wajir',
    description: ' The Wajir Charter happened in June 2020 and the people of Wajir turned up for the RESET book signing event. Their resilience and relentless nature to make sure they got the book is to be admired. Below are some amazing photos to reflect what happened in the Wajir Reset Charter. ',
    thumbnail: [event_1],
    images: [
       [event_1_1],
       [event_1_2],
       [event_1_3],
       [event_1_4],
       [event_1_5],
       [event_1_6],
       [event_1_7],
       [event_1_8],
    ],
  },
 
  {
    id: 3,
    name: 'reset in mombasa',
    thumbnail: [event_3],
    description: `In December 2020 the launch of Reset was in Mombasa and it was not a disappointment at all. We partnered with SWAHILI POT HUB for the launch. Mentor 001 and my team made sure this event was a success.

    Dive in for some of the beautiful images of the Reset Charter in Mombasa.`,
    images: [
      [event_3_1],
      [event_3_2],
      [event_3_3],
      [event_3_4],
      [event_3_5],
      [event_3_6],
      [event_3_7],
      [event_3_8],
   ],
  },
  {
    id: 5,
    name: 'Deception Launch',
    thumbnail: [event_5],
    description: `With our aim to help young authors, RESET and Misky Nur foundation was able to brighten the dream of Amal and made it a reality insuring more youth across the country as she launched her book called DECEPTION.`,
    images: [
      [event_5_1],
      [event_5_2],
      [event_5_3],
      [event_5_4],
      [event_5_5],
      [event_5_6],
      [event_3_5],
      [event_2_4],
    
   ],
  },
 
];

// foundation Events
export const eventsFoundationData = [
  {
    id: 6,
    name: 'Kilifi Pads drive and Girl child empowerment',
    thumbnail: [event_6],
    description: 'Together with Pure Pearl and many other organizations, we celebrated with the students of Mwakirunge girls secondary school during the International Day of the girl child. Pure Pearl gave me the opportunity to inspire young girls and mentor them.',
    images: [
      [event_6_1],
      [event_6_2],
      [event_6_3],
      [event_6_4],
      [event_6_5],
      [event_6_6],
      [event_6],
      [event_6_7],

  
   ],
  },
  {
    id: 4,
    name: 'Marry happy school mentorship',
    thumbnail: [event_4],
    description: 'Through our Misky Nur Foundation, we conduct mentorship that inspires the youth. It was an honor to share my story as a tool for energizing young girls in Marry happy school',
    images: [
      [event_4_1],
      [event_4_2],
      [event_4_3],
     
    
   ],
  },
];

