import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import { FaTimes } from 'react-icons/fa';

const LoginForm = () => {
  const [formData, setFormData] = useState({
    user_name: '',
    user_email: '',
    message: '',
    subject: '',
  });
  const [showAlert, setShowAlert] = useState(false);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Replace with misky's Email.js service ID, template ID, and user ID
    const serviceId = 'service_email';
    const templateId = 'template_6539uxg';
    const userId = 'ectIXcjfg9xRxCqA7';

    emailjs
      .send(serviceId, templateId, formData, userId)
      .then((response) => {
        console.log('SUCCESS', response.status, response.text);
        setShowAlert(true);
        setFormData({
          user_name: '',
          user_email: '',
          message: '',
          subject: '',
        });
        setTimeout(() => {
          setShowAlert(false);
        }, 5000); 
      })
      .catch((err) => {
        console.log('FAILED:', err);
        setShowAlert(true);
      });
  };

  const handleCancelAlert = () => {
    setShowAlert(false);
  };

  return (
    <section id="contact" className="pb-1 pt-8 px-10  bg-graient-to-t from-white to-red-50 " 
  
    >
      <div className="min-h-screen py-6 flex flex-col justify-center sm:py-20">
        <div className="relative py-3 sm:max-w-xl sm:mx-auto">
          <div className="absolute inset-0 bg-gradient-to-r from-accentColor to-secondaryColor shadow-lg transform -skew-y-6 sm:skew-y-0 sm:-rotate-6 sm:rounded-3xl"></div>
          <div className="relative px-4 py-10 bg-white shadow-lg sm:rounded-3xl sm:p-20">
            <div className="max-w-md mx-auto">
              <div>
                <h2 className="mb-1 lg:text-6xl font-playfair-display text-4xl text-center">Get In Touch</h2>
              </div>
              <form onSubmit={handleSubmit} className="divide-y divide-gray-200">
                {showAlert && (
                    <div className="fixed flex bottom-4 right-4 bg-purple-200 text-md text-black px-4 py-4 rounded shadow">
                      <p className='pr-4'>Thank you for contacting me.<br /> I will get back to you as soon as possible.</p>
                      <button onClick={handleCancelAlert} className="pl-4 border-red-300 border-l-2 text-red-700 text-lg">
                        <FaTimes />
                      </button>
                    </div>
                  )}
                <div className="py-8 text-base leading-6 space-y-4 text-gray-700 sm:text-lg sm:leading-7">
                  <div className="relative">
                    <input autoComplete="off" id="user_name" name="user_name" type="text" value={formData.user_name} onChange={handleChange} className="peer placeholder-transparent h-10 w-full border-b-2 border-gray-300 text-gray-900 focus:outline-none focus:border-purple-600" placeholder="Your Name" required />
                    <label htmlFor="user_name" className="absolute left-0 -top-3.5 text-gray-600 text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm">Your Name</label>
                  </div>
                  <div className="relative">
                    <input autoComplete="off" id="subject" name="subject" type="text" value={formData.subject} onChange={handleChange} className="peer placeholder-transparent h-10 w-full border-b-2 border-gray-300 text-gray-900 focus:outline-none focus:border-purple-600" placeholder="Subject" required />
                    <label htmlFor="subject" className="absolute left-0 -top-3.5 text-gray-600 text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm">Subject</label>
                  </div>
                  <div className="relative">
                    <input autoComplete="off" id="user_email" name="user_email" type="email" value={formData.user_email} onChange={handleChange} className="peer placeholder-transparent h-10 w-full border-b-2 border-gray-300 text-gray-900 focus:outline-none focus:border-purple-600" placeholder="Email address" required />
                    <label htmlFor="user_email" className="absolute left-0 -top-3.5 text-gray-600 text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm">Email Address</label>
                  </div>
                  <div className="relative">
                    <textarea autoComplete="off" id="message" name="message" value={formData.message} onChange={handleChange} className="peer placeholder-transparent h-20 w-full border-b-2 border-gray-300 text-gray-900 focus:outline-none focus:border-purple-600" placeholder="Your Message" required></textarea>
                    <label htmlFor="message" className="absolute left-0 -top-3.5 text-gray-600 text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm">Your Message</label>
                  </div>
                  <div className="relative">
                    <button type="submit" className="bg-secondaryColor hover:bg-purple-950 text-white rounded-md px-8 py-2">Submit</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default LoginForm;
