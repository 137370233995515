// Footer.js
import { Link } from 'react-router-dom';
import React from 'react';
import svgBackground from '../images/wave2.svg';
// import './Footer.css'; // Import your CSS file

import {
  IoLogoFacebook,
  IoLogoInstagram,
  IoLogoTwitter,
} from 'react-icons/io';

import { FaTiktok } from "react-icons/fa6";

const handleClick = () => {

  window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth" 
  });
};

const Footer = () => {
  return (


<footer class="relative border border-purple-300 border-r-0  border-b-0  border-l-0 pt-8 pb-6" style={{ backgroundImage: `url(${svgBackground})`, backgroundSize: 'cover', backgroundPosition: 'center' }} >
  <div class="container  mx-auto px-4">
    <div class="flex flex-wrap text-left lg:text-left">
      <div class="w-full  lg:w-6/12 px-4">
        <h4 class="lg:text-4xl  text-3xl mb-4  font-semibold ">Let's keep in touch!</h4>
        <h5 class="text-xl mt-0 mb-2 ">
          Find me on any of these platforms, I respond within 24 hours.
        </h5>
        <div className="mt-6  lg:mb-0 mb-6">
              <a href='https://twitter.com/MiskyHaji' className=" text-blue-400  font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-8" target='blank' type="button">
                <IoLogoTwitter className="h-10 w-10" />
              </a>
              <a href='https://www.facebook.com/misky.nur.5' target='blank'  className=" text-blue-600  font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-8" type="button">
                <IoLogoFacebook className="h-10 w-10" />
              </a>
              <a href='https://www.instagram.com/misky_hajji/'  target='blank'   className="bg-white text-pink-400  font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-8" type="button">
                <IoLogoInstagram className="h-10 w-10" />
              </a>
              <a href='https://www.tiktok.com/@miskyhajji'  target='blank'  className="bg-white text-black  font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-4" type="button">
                <FaTiktok className="h-10 w-10" />
              </a>
          </div>
      </div>
     {/* quick links */}
      <div class="w-full   lg:w-6/12 px-">
        <div class="flex  flex-wrap items-top mb-5 md:mb-6">
        <div class="w-full lg:w-4/12 px-4 ml-auto">
          <span class="block uppercase text-blue-900 text-xl font-semibold mb-2">Quick Links</span>
          <ul class="list-unstyled flex lg:flex-col  flex-row gap-3 lg:gap-0">
            <li>
              <Link to="/" class="hover:text-blue-800 font-semibold block md:pb-2 underline text-lg" onClick={handleClick}>Home</Link>
            </li>
            <li>
              <Link to="foundation" class="hover:text-blue-800 font-semibold block md:pb-2 underline text-lg" onClick={handleClick}>Foundation</Link>
            </li>
            <li>
              <Link to="books" class="hover:text-blue-800 font-semibold block md:pb-2  underline text-lg" onClick={handleClick}>Books</Link>
            </li>
           
            <li>
              <Link to="podcast" class="hover:text-blue-800 font-semibold block md:pb-2 underline text-lg" onClick={handleClick}>Podcast</Link>
            </li>
          </ul>
        </div>
        </div>
      </div>

    </div>
    <hr class="my-2 md:my-6 border-purple-300"/>
    <div class="flex flex-wrap items-center md:justify-between justify-center">
      <div class="w-full md:w-4/12 px-4 mx-auto text-center">
        <div class="text-sm text-blueGray-500 font-semibold py-1">
           © Misky Nur, <span>2024</span><a href="https://www.shukri.tech/" class="text-blue-500 hover:text-gray-800" target="_blank"/> -  built by
          <a target='blank' className='hover:text-purple-400' href="https://www.shukri.tech/" class="text-blueGray-500 hover:text-blueGray-800"> Abdishukri Mohamed</a>.
        </div>
      </div>
    </div>
  </div>
</footer>
  );
};

export default Footer;
