import React from 'react';
import workcationLogo from '../../images/education.jpg'; 
import { aboutdata } from "../../data"
const About = () => {

  return (
    <section id="about" className=' bg-gradient-to-b from-white to-red-50 py-3 lg:py-'>

      <h2 className="mt-5 lg:p- p-2 text-5xl lg:text-6xl font-thin font-playfair-display text-secondaryColor text-center lg:mt- mb-2 lg:mb-6">
        <span className="relative">
           Who we are
          {/* <span className="border-b-4 border-black absolute bottom-0 left-0 right-0"></span> */}
        </span>
      </h2>

    <div className="  sm:mb-10 px-0  lg:px-0 lg:grid lg:grid-cols-4 md:grid-cols-2 lg:h-full">
        <div className="px-2 md:px-10 text-center lg:text-left lg:py-10 py-5  m-auto lg:col-span-2 mt-3 lg:mt-1  lg:w-full lg:px-5 lg:pt-0 lg:pb-0 lg:max-w-lg">
            <p className="text-xl text-black text-wrap leading-[30px] lg:leading-[44px]">Misky Nur Foundation is a Kenyan based nonprofit organization founded by Misky Hajji to support mainly Education and empower women and youth in the society. The aim is to make sure everyone gets quality education and good guidance to achieve their dreams without discrimination. Our team has expanded and we have spread out in different regions to make sure we cover all possible areas and help as many people as possible</p>
            <a href="#foundationevents" className="inline-flex bg-secondaryColor items-center justify-center text-white mt-5 px-5 py-3 lg:px-10 text-base font-medium hover:bg-gray-900 ">see our events</a>
        </div>

        <div 
        // data-aos="zoom-out-left" data-aos-duration="1000" 
          className="relative flex justify-center  lg:block lg:col-span-2">
            <img className="lg:w-[900px] md:w-[660px] w-[350px] h-[350px] lg:h-[500px] object-cover rounded-lg" src={workcationLogo} alt="misky teaching students" />
        </div>
    </div>
</section>



  );
};

export default About;
