import React from 'react';
import Hero from './Hero';
import About from './About';
import Hosts from './Hosts';
import Episodes from './Episodes';
import LandingPage from './LandingPage';
import { Helmet } from 'react-helmet-async';


const Podcast = () => {
  return (<section className='bg-backgroundColor'>

         <Helmet>
            <title>The Veiled podcast - spirited conversations that captivate both the eyes and ears. </title>
            <meta name="description" content="An empowering space where Muslimah share triumphs, tackle girl troubles, and explore the intersection of faith and contemporary life. Join us for candid conversations that unveil the strength, resilience, and diverse voices of Muslim women." />
            <link rel="canonical" href="https://miskynur.me/podcast" />
          </Helmet>

    {/* <Hero /> */}
    <LandingPage />
    <About />
    <Hosts />
    <Episodes />
    </section>
  );
};

export default Podcast;
