import React from 'react';

// import data
import { herodata } from '../../data';

const Aboutme = () => {
  // destructure features
  const { aboutMe } = herodata;

  return (
    <section id="about" className="lg:py-10  py-3 bg-gradient-to-b from-white to-red-50">
      <div className="container mx-auto max-w-7xl">
      <h2 className='lg:text-7xl text-5xl mb-12 lg:mb-20 text-secondaryColor font-thin text-center font-playfair-display font-semiold '>About Me</h2>
        <div className="grid   grid-cols-1 lg:grid-cols-2  gap-">
         
          <div className="lg:px-4 px-4  flex justify-center  py-9 lg:py-1  order-last lg:order-last">
            <p className="text-xl  text-secondaryColor text-wrap text-ight font-quicksand font-quicksand-normal leading-[30px] lg:leading-[44px]">{aboutMe.description}</p>
          </div>
       
          <div className=''>
            <div className='w-full flex justify-center '>
             {/* <div className='mt-8 mb-8 w-4 bg-gray-900'></div> */}
              <img
                data-aos='zoom-in-right' data-aos-duration='1200'
                src={aboutMe.image}
                alt="" 
                className="lg:w-[28rem]  w-[19rem] object-cover h-[24rem] lg:h-[35rem]"
                // className=" object-cover h-[390px] lg:h-[500px] w-[450px] order-first lg:order-first"
              />
            </div>
            {/* <div className='w-3/4 right-0 top-6 h-4 bg-gray-900'></div> */}
          </div>


        </div>
      </div>
    </section>
  );
};

export default Aboutme;
