import React, { useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faBars } from '@fortawesome/free-solid-svg-icons';
import avatar from "../images/logo4.png";

const Navbar = () => {
    const headerRef = useRef(null);
    const menuRef = useRef(null);

    const stickyHeaderFunc = () => {
        window.addEventListener("scroll", () => {
            if (document.body.scrollTop > 70 || document.documentElement.scrollTop > 70) {
                headerRef.current.classList.add("sticky_header");
            } else {
                headerRef.current.classList.remove("sticky_header");
            }
        });
    };

    useEffect(() => {
        stickyHeaderFunc();

        return () => window.removeEventListener("scroll", stickyHeaderFunc);
    }, []);

    const handleClick = () => {
        // Scroll to the top of the page
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth" 
        });
    };

    const toggleMenu = () => menuRef.current.classList.toggle('show_menu');

    return (
        <header ref={headerRef} className="w-full bg-accentColor h-[80px] leading-[80px] flex items-center">
            <div className="container">
                <div className="flex items-center justify-between">
                    {/* logo */}
                    <div className="flex items-center gap-[10px]">
                        <Link to="/" className="leading-[]">
                            <div>
                                <h2 className="lg:text-3xl text-2xl hover:text-purple-600 font-bold">Misky Nur</h2>
                                {/* <img src={avatar} alt="logo" className='md:h-16 md:w-36 w-28 object-contain' /> */}
                            </div>
                        </Link>
                    </div>
                    {/* ========== logo end  ====== */}
                    {/* =========== menu start ====== */}
                    <div className="menu" ref={menuRef} onClick={toggleMenu}>
                        <ul className="flex items-center gap-10">
                            <Link to="/" className="text-textColor text-lg hover:text-purple-600 hover:underline font-[600]" onClick={handleClick}>Home</Link>
                            <Link to="/foundation" className="text-textColor text-lg hover:text-purple-600 hover:underline font-[600]" onClick={handleClick}>Foundation</Link>
                            <Link to="/books" className="text-textColor text-lg hover:text-purple-600 hover:underline font-[600]" onClick={handleClick}>Books</Link>
                             <Link to="/podcast"  className="text-textColor text-lg opacit-50  hover:text-purple-600 font-[600]"  onClick={handleClick}>Podcast</Link>
                           {/* <div id="tooltip-animation" role="tooltip" class="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700">
                                 Tooltip content
                                <div class="tooltip-arrow" data-popper-arrow></div>
                            </div> */}
                        </ul>
                    </div>
                    {/* ===========menu end ====== */}
                    {/* ===========menu right ====== */}
                    <div className="flex items-center gap-4">
                        <div>
                            <a href="#contact" className="flex items-center gap-2 text-black hover:text-purple-600 font-[600] border-2 border-solid border-black py-2 px-4 rounded-[8px] max-h-[40px] hover:bg-smallTextColor  hover:font-[500] ease-in duration-300"><FontAwesomeIcon icon={faPhone} /> contact me</a>
                        </div>
                        <span onClick={toggleMenu} className="text-2xl text-smallTextColor md:hidden cursor-pointer">
                            <FontAwesomeIcon icon={faBars} />
                        </span>
                    </div>
                    {/* ===========menu end ====== */}
                </div>
            </div>
        </header>
    );
};

export default Navbar;
