import './App.css';
import Aos from "aos";
import { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Analytics } from '@vercel/analytics/react'; 
import Books from './components/Books/Books';
import Footer from './components/Footer';
import Foundation from './components/Foundation/Foundation';
import Landingpage from './components/home/Landingpage';
import Navbar from './components/Navbar';
import Podcast from './components/Podcast/Podcast';
import ContactForm from './components/home/ContactForm';
import ErrorPage from './ErrorPage';
import Alert from './components/Alert';

function App() {
  useEffect(() => {
    Aos.init();
  }, []);

  return (
    <div className="App">
      <Analytics /> 
      <Router>
        <Navbar />
        <Alert />
        <Routes>
          <Route path="/" element={<Landingpage/>} />
          <Route path="/foundation" element={<Foundation />} />
          <Route path="/books" element={<Books />} />
          <Route path="/podcast" element={< Podcast />} />
          {/* <Route path="*" element={<ErrorPage />} /> */}
        </Routes>
        <ContactForm />
        <Footer />
      </Router>
    </div>
  );
}

export default App;
