import React from 'react';
import { Typography } from "@material-tailwind/react";
import { awardsData } from '../../data'; 
import AOS from "aos";
import { useEffect } from "react";
import "aos/dist/aos.css";

const Awards = () => {

    const { awards } = awardsData; 

    useEffect(() => {
      AOS.init();
    }, []);

    return (
        <section id="awards" className="p-4 lg:p-2  bg-gradient-to-t from-white to-red-50 ">
          <h2 className='lg:text-7xl text-6xl mb-10 text-secondaryColor font-thin font-playfair-display text-center '>Awards</h2>
              {/* award names */}
              <div className='flex  mb-10 flex-wrap justify-center  items-center px-3 pb-2'>
            <div className='media-card' data-aos="fade-up" data-aos-duration="1000">
              <p className='text-[1rem] lg:text-lg'>top 100 most influential Student 2022</p>
            </div>
            <div className='media-card' data-aos="fade-up" data-aos-duration="1000" data-aos-delay="200">
              <p className='text-[1rem] lg:text-lg'>top 100 most influential Student 2021</p>
            </div>
            <div className='media-card' data-aos="fade-up" data-aos-duration="1000" data-aos-delay="400">
              <p className='text-[1rem] lg:text-lg'>Guest, Muslim awards Australia</p>
            </div>
            <div className='media-card' data-aos="fade-up" data-aos-duration="1000" data-aos-delay="600">
              <p className='text-[1rem] lg:text-lg'>Melbourne Somali Community Recognition</p>
            </div>
            <div className='media-card' data-aos="fade-up" data-aos-duration="1000" data-aos-delay="800">
              <p className='text-[1rem] lg:text-lg'>Exceptional Melbourne Community Contributor</p>
            </div>
          </div>
          <div className='gap-6 justify-center flex flex-wrap'>
              {awards.map((award, index) => (
                <figure key={index} className="relative h-72 w-[300px] lg:h-96 lg:w-[400px]" data-aos="fade-up" data-aos-duration="1000" data-aos-delay={index * 200}>
                  <img
                    className="h-full w-full rounded-xl object-cover object-center"
                    src={award.imageUrl}
                    alt={`Award ${index + 1}`}
                  />
                  <figcaption className="absolute lg:bottom-8 bottom-3 left-2/4 flex w-[calc(100%-4rem)] -translate-x-2/4 justify-between rounded-xl border border-white bg-white/75 py-4 px-2 shadow-lg shadow-black/5 saturate-200 backdrop-blur-sm">
                    <div>
                      <h6 className='text-black  font-semibold font-playfair-display text-xl  md:text-3xl'>
                        {award.title}
                      </h6>
                      <p className="mt-1 font-quicksand md:text-xl text-lg font-normal">
                        {award.description}
                      </p>
                    </div>
                    {/* <Typography variant="h5" color="blue-secondaryColor">
                        link
                      </Typography> */}
                  </figcaption>
                </figure>
              ))}
            </div>

        </section>
    );
};

export default Awards;
