import React from "react";
// images
import Img1 from "../../images/WAVECover.jpg";
import Img2 from "../../images/reset.jpeg";

// star icon
import { FaStar } from "react-icons/fa";

// data 
const BooksData = [
  {
    id: 1,
    img: Img1,
    title: "WAVES",
    description:
      `"Waves" is a poetry collection that explores social issues and the human experience. The author uses poems to tackle contemporary challenges and universal themes like love, pain, and identity. The poems are inspired by the author's observations of society's diversity and struggles. They aim to spark conversations about the social issues that impact our lives.`,
  },
  {
    id: 2,
    img: Img2,
    title: "RESET",
    description:
      "RESET is a poetry collection that tackles the tough stuff: social injustice, economic woes, and political gridlock. But it doesn't stop there. We see the struggles of our youth and celebrate the resilience of pastoral communities. RESET is a call to action, a reminder that change starts with us.  Dive in, RESET your perspective, and find the hope we all need.",
  },
//   {
//     id: 3,
//     img: Img3,
//     title: "Lost Boy",
//     description:
//       "lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
//   },
];

const MyBooks = ({ handleOrderPopup }) => {
  return (
      <section id="mybooks" className="py-10 md:py-3 ">
        <div className="container">
          <div className="text-center mb-32 max-w-[600px] mx-auto">
          <h2 className="text-5xl font-playfair-display md:text-6xl lg:text-7xl font-thin text-center lg:mt-7 mb-4 lg:mb-6">
             My Books
          </h2>
            <p className="text-lg text-secondaryColor">
              Explore My collection and discover poems that will resonate with your soul. Check out my books and embark on a journey of self-discovery
            </p>
          </div>
          <div className="grid bg-gren-500 grid-cols-1 sm:grid-cols-2 md:grid-cols-2 gap-20 md:gap-5 place-items-center">
            {BooksData.map((service) => (
              <div
                // data-aos="zoom-in"
                className="rounded-2xl mt-10 bg-white p-2  text-black hover:textwhite hover:bg-secondaryolor  relative shadow-xl duration-high group max-w-[400px]"
              >
                <div className="h-[200px]">
                  <img
                    src={service.img}
                    alt=""
                    className="max-w-[200px] block mx-auto transform -translate-y-24
                     group-hover:scale-105  duration-300 shadow-md
                  "
                  />
                </div>
                <div className="p-2 text-center">
                  <div className="w-full flex items-center justify-center gap-1">
                    <FaStar className="text-yellow-500" />
                    <FaStar className="text-yellow-500" />
                    <FaStar className="text-yellow-500" />
                    <FaStar className="text-yellow-500" />
                  </div>
                  <h1 className="text-xl font-bold">{service.title}</h1>
                  <p className="text-gray-900 mb-3 group-hover:text-hite duration-high text-lg line-clam">
                    {service.description}
                  </p>
                  {/* <div className="flex gap-2 flex-col lg:flex-row ">
                    <button
                        className="bg-secondaryColor rounded-md hover:scale-105 duration-300 text-white py-2 px-4  mt-0 group-hover:bg-whte group-hover:text-secondryColor"
                        onClick={handleOrderPopup}
                     >
                        Order from amazon(overseas)
                     </button>
                     <button
                        className="bg-secondaryColor rounded-md hover:scale-105 duration-300 text-white py-2 px-4  mt-0 group-hover:bg-whte group-hover:text-secndaryColor"
                        onClick={handleOrderPopup}
                     >
                        Order from nuria store(kenya)
                     </button>
                    </div> */}
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
  );
};

export default MyBooks;
