import BookLandingPage from "./BooksLandingPage";
import EventTabs from "./EventsTab";
import MyBooks from "./MyBooks";
import BookHero from "./BookHero";
import Testimonial from "./Testomonial";
import { Helmet } from 'react-helmet-async';

function Books() {
    return (
      <section id="books" className="app">

          <Helmet>
            <title>Misky Nur's Books</title>
            <meta name="description" content="Find poems that resonate with your soul, offering a gentle reset for your spirit. Dive into captivating books and emerge transformed, ready to embrace a new tide in your life." />
            <link rel="canonical" href="https://miskynur.me/books" />
          </Helmet>

         <BookHero />
         <MyBooks  />
         <EventTabs />
         <Testimonial />
         
      </section>
    );
  }
  
  export default Books;