import React from "react";

import { socials } from '../../data'; 

const SocialLinks = () => {
  return (
    <div className="section flex mt-12 justify-center gap-x-16 flex-wrap md:flex-row sm:flex-col">
    {socials.social.map((item, index) => (
      <div className="font-semibold mb-4 md:mb-0 lg:mb-0 flex transition items-center" key={index}>
            <a
              className="text-socialColor text-4xl lg:text-6xl mr-2"
              href={item.href}
              target="_blank"
              rel="noopener noreferrer"
              aria-label={`Visit ${item.socialMediaName} with ${item.numberOfFollowers} followers`}
            >
              {item.socialmediaicon}
            </a>
            <div className="flex flex-col  font-quicksand  text-secondaryColor hover:text-accentColor hover:cursor-pointer">
              <a
                href={item.href}
                target="_blank"
                rel="noopener noreferrer"
                aria-label={`Visit ${item.socialMediaName} with ${item.numberOfFollowers} followers`}
                className=""
              >
                {item.numberOfFollowers}
              </a>
              <a
                href={item.href}
                target="_blank"
                rel="noopener noreferrer"
                aria-label={`Visit ${item.socialMediaName} with ${item.numberOfFollowers} followers`}
                className=""
              >
                followers
              </a>
            </div>
          </div>
        ))}
      </div>
  
  
  );
};

export default SocialLinks;
