import { useState } from 'react';
import { eventsData } from '../../data'; // Importing eventsData from your data.js file

const EventTabs = () => {
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  const handleTabClick = (index) => {
    setSelectedTabIndex(index);
  };

  const selectedEvent = eventsData[selectedTabIndex];

  return (
    <section id='bookevents' className="max-[1300px] bg-re-200 mx-auto lg:mt-20 mt-8">

   
        <div className="text-center mb-3 max-w-[900px] mx-auto">
          <h2 className="text-5xl font-playfair-display md:text-6xl lg:text-7xl font-thin text-center lg:mt-7 mb-4 lg:mb-10">
             Book events
          </h2>
            {/* <p className="text-lg text-secondaryColor">
             "RESET" events were amazing! We tackled social justice, youth power, and the magic of poetry for change. The People's energy ignited a collective spirit of change. Let's keep the fire burning! Stay tuned for future events and remember: we can all RESET, together.
            </p> */}
          </div>
      {/* tabs */}
      <div className='flex  justify-center'>
      <div className='lg:w-5/6 md:w-5/6 w-full  text-center grid lg:grid-cols-6 md:grid-cols-2  border border-gray-200 shadow-sm roundd-full'>
        {eventsData.map((event, index) => (
            <div
            key={index}
            className={`col-span-1 border border-purple-200   lg:border-none round py-3 cursor-pointer ${
                selectedTabIndex === index ? 'font-bld text-lg   bg-purple-200 transition duration-3000 ease-in-out' : 'text-black  text-lg'
            }`}
            onClick={() => handleTabClick(index)}
            >
            {event.name}
            </div>
        ))}
        </div>
        </div>

      <div className="containr bg-oange-500 mx-auto">
        <div className=" bg-gren-500  gap-4">
          <div className="px-5  lg:px-0">
            <div className="text-center mt-4">
              {/* <h2 className="text-3xl font-bold">{selectedEvent.name}</h2> */}
              <p className="text-lg lg:mx-40 md:mx-4 mx-2  leading-8 mb-4 bg-rd-500">{selectedEvent.description}</p>
            </div>
          </div>
          <div className="grid bg-white grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-4 gap-1">
            {selectedEvent.images.map((image, index) => (
              <div
                key={index}
                // data-aos="flip-left"
                // data-aos-easing="ease-out-cubic"
                // data-aos-duration="2000"
                className="group cursor-pointer relative"
              >
                <img
                  src={image}
                  alt={selectedEvent.name}
                  className="w-full h-80 object-cover rouned-lg transition-transform transform scale-100 group-hover:opacity-80"
                />
                <div className="absolute inset-0 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity">
                  {/* <button className="bg-white text-gray-800 px-4 py-2 rounded-lg hover:bg-gray-200 transition-colors">
                    View
                  </button> */}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default EventTabs;
