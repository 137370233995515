import React from 'react';
import {  FaTwitter, FaTiktok, FaInstagram, FaFacebook } from 'react-icons/fa';
import misky from "../../images/misky18.png"
const Hosts = () => {
    return (
        <section id='hosts' className="">

                <h2 className=" text-4xl font-playfair-display md:text-5xl lg:text-6xl font-thin text-center lg:mt-3 mb-20 lg:mb-20">
                    Our Amazing Host
                </h2>
         

            <div className="w-full  lg:px-10 md:px-0  pt-10">
                <div className="contaier  mx-auto">
                    <div role="list" aria-label="hosts of veiled podcasts(misky and bilhan) "
                     className="lg:flex md:flex sm:flex items-center xl:justify-center lg:gap-48 md:gap-20 flex-wrap md:justify-center  sm:justify-around lg:justify-center">
                        {/* Team member 1 */}
                        <div role="listitem" className="xl:w-1/3  sm:w-3/4 md:w-2/5 relative mt-1 mb-10 sm:mb-24 xl:max-w-sm lg:w-2/5">
                            <div className="rounded overflow-hidden bg-orange-200 shadw-md " 
                            // style={{background : "#fdf3f4"}}
                            >
                                <div className="absolute -mt-20 w-full flex justify-center">
                                    <div className="h-32 w-32">
                                        <img 
                                         src={misky}
                                         alt="Display Picture of misky nur" role="img" className="rounded-full object-cover bg-orange-200 h-full w-full shaow-md" 
                                        //  style={{background : "#ea9daa"}} 
                                         />
                                    </div>  
                                </div>
                                <div className="px-6 mt-16">
                                    <h1 className="font-bold text-3xl text-center mb-1">Misky Nur</h1>
                                    <p className="text-gray-800 text-sm text-center">Host</p>
                                    <p className="text-center text-gray-600 text-base pt-3 font-normal">Misky Nur is a young author, poet, advocate, and aspiring lawyer. She is striving to make a difference in her community and beyond. Join Misky on this journey as she explores the power of words and advocates for positive change on this podcast.</p>
                                    <div className="w-full flex justify-center pt-5 pb-5">

                                        <a href="https://twitter.com/MiskyHaji" target='blank' className="mx-5">
                                            <div aria-label="Twitter" className='text-blue-500' role="img">
                                              <FaTwitter size={24} color="" />
                                            </div>
                                        </a>
                                        <a href="https://www.tiktok.com/@miskyhajji" target='blank' className="mx-5">
                                            <div aria-label="TikTok" role="img">
                                            <FaTiktok size={24} color="black" />
                                            </div>
                                        </a>
                                        <a href="https://www.instagram.com/misky_hajji/" target='blank' className="mx-5">
                                            <div aria-label="Instagram" role="img">
                                            <FaInstagram size={24} color="red" />
                                            </div>
                                        </a>
                                        <a href="https://www.facebook.com/misky.nur.5" target='blank' className="mx-5">
                                            <div aria-label="Facebook" className='text-blue-800' role="img">
                                            <FaFacebook size={24} color="" />
                                            </div>
                                        </a>
                                        </div>
                                </div>
                            </div>
                        </div>
                        {/* Team member 2 */}
                        {/* <div role="listitem" className="xl:w-1/3 sm:w-3/4 md:w-2/5 relative mt-16 mb-32 sm:mb-24 xl:max-w-sm lg:w-2/5">
                            <div className="rounded bg-Wawek-800 bg-orange-200 overflow-hidden shadw-md "
                            //    style={{background : "#fdf3f4"}}
                               >
                                <div className="absolute -mt-20 w-full flex justify-center">
                                    <div className="h-32 w-32">
                                        <img 
                                          src={misky}
                                         alt="Display Picture of Bilhan" role="img" className="rounded-full bg-orange-200  object-cover h-full w-full shadw-md" 
                                        //  style={{background : "#ea9daa"}}
                                         />
                                    </div>
                                </div>
                                <div className="px-6 mt-16">
                                    <h1 className="font-bold text-3xl text-center mb-1">Bilhan</h1>
                                    <p className="text-gray-800 text-sm text-center">Host</p>
                                    <p className="text-center text-gray-600 text-base pt-3 font-normal">Misky Nur is a young author, poet, advocate, and aspiring lawyer. She is striving to make a difference in her community and beyond. Join Misky on this journey as she explores the power of words and advocates for positive change on this podcast.</p>
                                     
                                    <div className="w-full flex justify-center pt-5 pb-5">

                                        <a href="https://twitter.com/MiskyHaji" target='blank' className="mx-5">
                                            <div aria-label="Twitter" className='text-blue-400' role="img">
                                            <FaTwitter size={24} color="" />
                                            </div>
                                        </a>
                                        <a href="https://www.tiktok.com/@miskyhajji"  target='blank' className="mx-5">
                                            <div aria-label="TikTok" role="img">
                                            <FaTiktok size={24} color="black" />
                                            </div>
                                        </a>
                                        <a href="https://www.instagram.com/misky_hajji/" target='blank' className="mx-5">
                                            <div aria-label="Instagram" role="img">
                                            <FaInstagram size={24} color="red" />
                                            </div>
                                        </a>
                                        <a href="https://www.facebook.com/misky.nur.5" target='blank' className="mx-5">
                                            <div aria-label="Facebook" className='text-blue-800' role="img">
                                            <FaFacebook size={24} color="" />
                                            </div>
                                        </a>
                                        </div>
 
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Hosts;
