import React from 'react';
import CountUp from 'react-countup';

const Impact = () => {
    return (
        <div className="bg-gradient-to-b from-gray-100 to-white text-black py-8 px-4 sm:px-6 lg:px-8  items-center">
            <div>
                <div className="text-center">
                    <h2 className="mt-2 lg:p-8 font-playfair-display p-2 text-5xl lg:text-6xl font-thin text-center lg:mt-0 mb-6 lg:mb-4">
                        <span className="relative">
                            Our Impact
                            {/* <span className="border-b-4 border-black absolute bottom-0 left-0 right-0"></span> */}
                        </span>
                    </h2>
                    <p className="text-xl text-black text-wrap leading-[30px] lg:leading-[32px] px-1 lg:px-32">
                        We’ve worked on improving the lives of many young people through direct practice. Young people from different backgrounds have a huge impact on us and all our activities. They highlight those parts of the society that are broken, so we can help them in all possible ways to regain hope and flourish in life.
                    </p>
                </div>
                <div data-aos="flip-right" data-aos-duration="1000"   className="mt-12 flex justify-center ">
                    <div className="bg-accentColor px-10 py-6 rounded-lg text-center">
                        <h3 className="text-lg font-medium text-black">Our numbers that speak</h3>
                        <p className="mt-2 text-4xl font-extrabold">
                            <CountUp start={0} end={100000} duration={2} separator="," /> +
                        </p>
                        <p className="mt-2 text-lg">Lives Impacted</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Impact;
