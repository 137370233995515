import React from "react";
import Book1 from "../../images/WAVECover.jpg";
import Book2 from "../../images/reset.jpeg";
import Book3 from "../../images/book3.jpg";
import Vector from "../../images/blue-pattern.png";
import Order from "./Order";

const ImageList = [
  {
    id: 1,
    img: Book1,
    title: "WAVES",
    description:
`"Waves" is a poetry collection that explores social issues and the human experience. The author uses poems to tackle contemporary challenges and universal themes like love, pain, and identity. The poems are inspired by the author's observations of society's diversity and struggles. They aim to spark conversations about the social issues that impact our lives.`,  },
  {
    id: 2,
    img: Book2,
    title: "RESET",
    description:
      "RESET is a poetry collection that tackles the tough stuff: social injustice, economic woes, and political gridlock. But it doesn't stop there. We see the struggles of our youth and celebrate the resilience of pastoral communities. RESET is a call to action, a reminder that change starts with us.  Dive in, RESET your perspective, and find the hope we all need.",
  },
];

const Hero = ({ handleOrderPopup }) => {

  const [imageId, setImageId] = React.useState(Book1);
  const [title, setTitle] = React.useState("Waves");
  const [description, setDescription] = React.useState(
 `"Waves" is a poetry collection that explores social issues and the human experience. The author uses poems to tackle contemporary challenges and universal themes like love, pain, and identity. The poems are inspired by the author's observations of society's diversity and struggles. They aim to spark conversations about the social issues that impact our lives.`,  );

  const bgImage = {
    backgroundImage: `url(${Vector})`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    // height: "100%",
    width: "100%",
  };

  return (
    <>
      <div
        className="min-h-[550px] sm:min-h-[600px]  flex justify-center items-center   duration-200"
        // style={bgImage}
      >
        <div className="containr lg:px-20 px-2  md:px-4 pb-8 sm:pb-0">
          <div className="grid  grid-cols-1 sm:grid-cols-2">
            {/* text content section */}
            <div
              data-aos-once="true"
              className="flex flex-col  justify-center gap-4 pt-12 sm:pt-0 text-center sm:text-left order-2 sm:order-1"
            >
              <h1
                data-aos="zoom-out"
                data-aos-duration="500"
                data-aos-once="true"
                className="text-5xl md:mb-4  sm:text-6xl leading-loose font-plafair-display lg:text-7xl font-bold"
              >
                {title}
                {/* <p class="bg-clip-text text-transparent bg-gradient-to-b from-primary text-right text-sm to-secondary">
                  by Anonymous
                </p>{" "} */}
              </h1>
              <p
                // data-aos="slide-up"
                // data-aos-duration="500"
                // data-aos-delay="100"
                className="text-lg mb-7 leading-8 "
              >
                {description}
              </p>
              {/* cta btns */}
              <Order />

            </div>

            {/* Image section */}
            <div className="min-h-[450px] sm:min-h-[450px] flex justify-center items-center relative order-1 sm:order-2 ">
              <div className="h-[300px] sm:h-[450px] overflow-hidden flex justify-center items-center">
                <img
                  data-aos="zoom-in"
                  data-aos-once="true"
                  src={imageId}
                  alt=" book cover img"
                  className="w-[400px] h-[300px] sm:h-[450px] sm:w-[450px] sm:scale-125 object-contain mx-auto"
                />
              </div>
              <div className="flex lg:flex-col lg:top-1/2 lg:-translate-y-1/2 lg:py-2 justify-center gap-4 absolute -bottom-[40px] lg:-right-1 bg-whte rounded-ful">
                {ImageList.map((item) => (
                  <img
                    data-aos="zoom-in"
                    data-aos-once="true"
                    src={item.img}
                    onClick={() => {
                      setImageId(
                        item.id === 1 ? Book1 : item.id === 2 ? Book2 : Book3
                      );
                      setTitle(item.title);
                      setDescription(item.description);
                    }}
                    alt="book cover img"
                    className="max-w-[100px] h-[100px] object-contain inline-block  hover:scale-100 duration-200"
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Hero;
