
import React from "react";
import Slider from "react-slick";

// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import images
import farah from "../../images/farah.jpeg"
import kingi from "../../images/kingi.jpeg"
import simon from "../../images/simon.jpeg"
const testimonialData = [
  {
    id: 1,
    name: "Hon. Farah Maalim EGH - MP Dadaab",
    text: "when i first met you about three years ago. I knew I met a budding star. You are full of ideas and energy. Walk head high with giant steps and conquer the world of literature. With your determination everything is possible. My Prayers are with you princess daughter. ",
    img: farah,
  },
  {
    id: 1,
    name: "Dr. William K. Kingi - deputy Gov. Mombasa",
    text: "Misky Nur Abdullah is such a remarkable poet. At such a tender age one can only mervel at the exellent writing skills of this young author. She has just published her first book titled #reset. And I recommend that you have a look at it. It is worth your time.",
    img: kingi,
  },
  {
    id: 1,
    name: "Simon Mordue - European Policy Advisor",
    text: "This morning I met youth advocate and poet @miskyhaji and had interesting discussions on the need to promote opportunities for youth in norther Kenya and her efforts to #EndFGM ",
    img: simon,
  },
];

const Testimonial = () => {
  var settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    // slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    cssEase: "linear",
    pauseOnHover: true,
    pauseOnFocus: true,
    responsive: [
      {
        breakpoint: 10000,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <>
      <div data-aos="fade-up" data-aos-duration="300" className="py-10">
        <div className="container">
          <div className="text-center mb-20 max-w-[600px] mx-auto">
            {/* <p className="text-sm bg-clip-text text-transparent bg-gradient-to-r from-primary to-secondary">
              What our customers say
            </p> */}
             <h2 className="text-5xl font-playfair-display md:text-6xl lg:text-7xl font-thin text-center lg:mt-7 mb-4 lg:mb-6">
                Testimonials
            </h2>
            {/* <p className="text-md text-gray-900">
              Lorem ipsum dolor, sit amet consectetur adipisicing elit.
              Perspiciatis delectus architecto error nesciunt,
            </p> */}
          </div>
          <div
            // data-aos="zoom-in"
            // data-aos-duration="300"
            className="grid grid-cols-1 max-w-[1000px] mx-auto gap-6"
          >
            <Slider {...settings}>
              {testimonialData.map((data) => {
                return (
                  <div className="my-6">
                    <div
                      key={data.id}
                      className=" flex  bg-accentColor flex-col gap-4 shadow-lg py-8 px-6 mx-4 rounded-xl dark:bg-gay-800 bg-pimary/10 relative"
                    >
                      <div>
                        <img
                          className="rounded-full w-20 h-20"
                          src={data.img}
                          alt="img of farah maalim"
                        />
                      </div>
                      <div className="flex  items-center gap-4">
                        <div>
                          <p className="text-gray-900  mb-3 text-md">{data.text}</p>
                          <h1 className="text-xl font-bold text-black/80 dark:tet-light">
                            {data.name}
                          </h1>
                        </div>
                      </div>

                      <p className="text-black/20 text-9xl font-serif absolute top-0 right-0">
                        ,,
                      </p>
                    </div>
                  </div>
                );
              })}
            </Slider>
          </div>
        </div>
      </div>
    </>
  );
};

export default Testimonial;
