import React, { useEffect } from 'react';
import About from "./About";
import FLandingPage from "./FLandingPage"
import Impact from "./Impact";
import Mission from "./Mission";
import WhatWeCare from "./WhatWeCare";
import WhatWeDo from "./WhatWeDo";
import FoundationEventsTabs from "./FoundationEventsTabs"
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Helmet } from 'react-helmet-async';


function Foundation() {

  useEffect(() => {
    AOS.init();
  }, []);


    return (
      <div className="App">
          <Helmet>
            <title>misky nur| foundation</title>
            <title>Misky Nur Foundation - Empowering Communities</title>
            <meta name="description" content="Join us in empowering communities through education and support. Learn about our initiatives to provide quality education and guidance." />
            <link rel="canonical" href="https://miskynur.me/foundation" />
          </Helmet>

       <FLandingPage />
       <About />
       <Mission />
       <Impact />
       <WhatWeDo />
       <WhatWeCare />
       <FoundationEventsTabs />
     
      </div>
    );
  }
  
  export default Foundation;