import React from 'react';
import { WhatWeCaredata } from '../../data'; 

const WhatWeCare = () => {
  return (
    <section id='whatwecare' className='py-2'>
        <h2 className="mt-5 lg:p-8 p-2 text-4xl font-playfair-display md:text-5xl lg:text-6xl font-thin text-center lg:mt-7 mb-12 lg:mb-8">
             What we Care For
        </h2>
      <div className="  flex justify-center  items-center">
        <div className="flex px-3 sm:px-0 flex-col gap-12 justify-center sm:flex-row sm:justify-center sm:flex-wrap">
          {WhatWeCaredata.map((item, index) => (
            <div
            //  data-aos="fade-right"
            //   data-aos-duration="1000"
               key={index} 
              className="flex flex-col rounded-md justify-between w-full sm:w-80 md:w-88 h-96 bg-white bg-center text-gray-800 shadow-md overflow-hidden cursor-pointer" style={{ backgroundImage: `url(${item.image})`, backgroundSize: 'cover' }}>

              <div className="flex justify-between items-center ml-4 pr-8">
                <h2 className="bg-purple-400 text-black bg-opacity-95 shadow px-2 py-1 flex items-center font-bold text-2xl rounded">{item.title}</h2>
                <div className="bg-purple-400 w-10 h-12 shadow flex flex-col-reverse p-2 text-center font-bold text-black rounded-b-full">{item.num}</div>
              </div>
              <div className="bg-white bg-opacity-95 shadow-md rounded-r-xl p-4 flex flex-col mr-4 mb-8 backdrop-blr-sm">
                <p className=" text-gray-900 text-pretty text-normal">{item.description}</p>
               
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default WhatWeCare;
