import React, { useState } from 'react';

function Alert() {
  const [showAlert, setShowAlert] = useState(true);

  const handleDismiss = () => {
    setShowAlert(false);
  };

  return (
    showAlert && (
      <div className="shadow bg-slae-800 fixed absolte top-14 z-50 bg-transparet rounded-md w-full max-w-screen mx-auto mt-8" role="alert">
        <div className="flex">
          <div className="bg-accentColor w-16 text-center p-2">
            <div className="flex justify-center h-full items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6 text-white"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
                />
              </svg>
            </div>
          </div>
          <div className="bg-white border-r-4 flex justify-between border-accentColor w-full p-2">
            <div>
              {/* <p className="text-gray-600 font-bold">Great news</p> */}
              <p className="text-gray-900 text-md lg:text-lg">
                My latest book, Waves is now live on Amazon,
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.amazon.com/dp/B0CW1N2YFV?ref_=pe_93986420_775043100"
                  className="text-secondaryColor underline"
                >
                  click here
                </a>{' '}
                to pre-order now
              </p>
            </div>
            {/* close btn */}
            <div className=''>
                <button
                className="text-red-500 ml-2 focus:outline-none"
                onClick={handleDismiss}
            >
                <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                >
                <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                />
                </svg>
            </button>
          </div>
          </div>
          
        </div>
      </div>
    )
  );
}

export default Alert;
