import React, { useState, useRef, useEffect } from 'react';

function Order() {
  const [selectedOption, setSelectedOption] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const popupRef = useRef(null);

  const handleOrderOption = (option) => {
    setSelectedOption(option);
    setShowPopup(false); // Close the popup after selecting an option
  };

  const handleOrderPopup = () => {
    setShowPopup(!showPopup); // Toggle the popup visibility
  };

  useEffect(() => {
    // Function to close popup when user clicks outside
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setShowPopup(false);
      }
    };

    // Add event listener to close popup when user clicks outside
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      // Clean up event listener when component unmounts
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const nuriaStoreLink = 'https://nuriakenya.com/product/waves-by-misky-nur/';
  const amazonEbookLink = 'https://www.amazon.com/dp/B0CW1N2YFV?ref_=pe_93986420_775043100';

  return (
    <div className="flex gap-4 flex-col lg:flex-row">
 
        <button
          className="bg-secondaryColor hover:scale-105 text-lg duration-200 text-white py-3 px-4"
        > 
         <a href={amazonEbookLink}
            target="_blank"
            rel="noopener noreferrer">
           Get e-book from amazon
         </a>
        </button>
   
      <div className="relative">
        <button
          onClick={handleOrderPopup}
          className="bg-secondaryColor text-lg hover:scale-105 duration-200 text-white py-3 px-4"
        >
          Get a hard copy
        </button>
          {showPopup && (
            <div ref={popupRef} className="absolute z-10 top-full left-0 bg-gray-800 border border-gray-200 mt-1 py-1 w-full rounded shadow-lg">
            
              <a
                href={nuriaStoreLink}
                target="_blank"
                rel="noopener noreferrer"
                className="block w-full text-left px-3 py-1 text-white border border-b-1 border-t-0 hover:bg-secondaryColor"
              >
                 Nuria Store - Kenya
              </a>
              <a
                href={amazonEbookLink}
                target="_blank"
                rel="noopener noreferrer"
                className="block w-full text-left px-3 py-1 text-white hover:bg-secondaryColor"
              >
                , or from Amazon
              </a>
            </div>
          )}
        </div>
    </div>
  );
}

export default Order;
